<template lang="pug">
.CurrentUser
  .ContainerHeader.pb-5
    .d-flex.align-items-center.justify-content-between
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.ml-3.backButton
      h3.fontTitleHeader.m-0 Elegir usuario
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.opacity-0.mr-3.backButton
  .ContainerData.pt-3.px-2
    h6.subTitle Usuarios registrados
    ul.containerCards
      li(v-for="user in users" :key="user.id").liCard.mx-auto.px-3.pt-3.pb-4.my-3
        p.mb-0.fontLiCard
          strong Nombre:
          span.ml-1 {{ user.name }}
        p.mb-0.fontLiCard
          strong Número de documento:
          span.ml-1 {{ user.docNumber }}
        .w-100.d-flex.justify-content-end.mt-3
          button(@click="onContinue" :disabled="charging").buttonConfirm.buttonSelect.px-4.py-2
            span(v-if="!charging") Seleccionar
            span(v-else)
              MiniSpinner
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CurrentUser",

  components: {
    MiniSpinner: () => import("../../components/MiniSpinner")
  },

  data() {
    return {
      users: [
        {
          id: 1,
          name: "John Richard",
          docNumber: "4546454654654564"
        },
        {
          id: 2,
          name: "Santiago Paulo",
          docNumber: "124545"
        },
        {
          id: 3,
          name: "Therd",
          docNumber: "45788"
        },
        {
          id: 4,
          name: "Shina Rojas",
          docNumber: "12000004"
        },
        {
          id: 5,
          name: "Plain Gray",
          docNumber: "10450000"
        }
      ]
    };
  },

  computed: {
    ...mapState({
      charging: state => state.mobileFSFB.charging,
      docInfo: state => state.mobileFSFB.docInfo
    }),

    toSend() {
      return {
        ...this.docInfo,
        CodigoOficina: "1?area=admisiones_1"
      };
    }
  },

  methods: {
    ...mapActions({
      goToView: "mobileFSFB/goToView",
      authenticate: "mobileFSFB/authenticate",
      sendTracking: "mobileFSFB/handleTracking"
    }),

    async onContinue() {
      await this.authenticate(this.toSend);

      // tracking
      setTimeout(() => {
        let origin = "mobile_fsfb";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    }
  }
};
</script>

<style>
.ContainerHeader {
  background-color: var(--color-radiant-blue);
  margin-bottom: -30px;
}

.CurrentUser {
  background-color: var(--color-bg-2-white);
  height: 100%;
}

.ContainerData {
  border-radius: 20px 20px 0 0;
  background-color: var(--color-bg-2-white);
  max-height: calc(100vh - 82px);
  overflow-y: auto;
}

.subTitle {
  font-size: 18px;
  line-height: 25px;
  margin: 0 23px;
  font-family: var(--font-secondary);
  color: var(--color-radiant-blue);
}

.fontTitleHeader {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--color-white);
  padding-top: 18px;
}

.liCard {
  width: 325px;
  border-radius: 16px;
  list-style: none;
  box-shadow: 0px 1px 4px rgba(21, 21, 21, 0.02),
    0px 8px 12px rgba(21, 21, 21, 0.08);
  background: var(--color-white);
}

.backButton {
  padding-top: 21px;
}

.buttonSelect {
  font-size: 13px;
  line-height: 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-white);
}

.fontLiCard {
  color: var(--color-gray);
}

.fontLiCard strong {
  font-family: var(--font-secondary);
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
</style>
